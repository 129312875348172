import React from 'react';
import { useDispatch} from 'react-redux'
import { setHref } from '../features/board/boardSlice';


export const PlayerItem = (props) => {
    const dispatch = useDispatch()

    const onSelectPlayer = () => {
                let queryString = window.location.search
                let urlParams = new URLSearchParams(queryString)
                urlParams.set("PlayerStats", props.player.playerName)
                let newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();
                window.history.pushState(null, '', newRelativePathQuery);
                dispatch(setHref(window.location.href)) 
            }

    const playerName = props.player.playerName
    let buttonClass = props.player.playerName === props.selectedPlayer ? 'PlayerItem-Button-Selected' : 'PlayerItem-Button'
    
    return<div>
        <button className={buttonClass} onClick={onSelectPlayer}>
            {playerName}
        </button>
    </div>
}