import { createSlice } from '@reduxjs/toolkit'

export const boardSlice = createSlice({
    name: 'board',
    initialState: {
        allGames: [],
        currentGame: {},
        isGameSelected: false,
        isCreatingNewGame: false,
        isShotFormOpen: false,
        players: [],
        shots: [],
        rules: {},
        menuState: "ShowGameList",
        href: ""
    },
    reducers:{
        updateGames: (state, action) => {
            state.allGames = action.payload
        },
        selectGame: (state, action) => {
            state.currentGame = action.payload
            state.isGameSelected = true
            state.isCreatingNewGame = false
            state.menuState = "ShowGame"
        },
        setupNewGame: (state, action) => {
            state.currentGame = action.payload
            state.isGameSelected = false
            state.isCreatingNewGame = true
        },
        abortNewGame: (state, action) => {
            state.isGameSelected = false
            state.isCreatingNewGame = false
        },
        addNewGame: (state, action) => {
            state.isCreatingNewGame = false
        },
        updateGameRules: (state, action) => {
            let rulesResponse = action.payload[0]

            let splitPointsArray = []
            rulesResponse.normalPoints.split(';').forEach(shotPoint => {
                splitPointsArray.push(parseInt(shotPoint,10))
            });
            state.rules = {
                rulesID: rulesResponse.id,
                gameID: rulesResponse.gameID,
                gameMode: "mode" + rulesResponse.mode,
                maxShots: rulesResponse.maxShot,
                normalPoints: splitPointsArray,
                bullseyePoints: parseInt(rulesResponse.bullseyePoints,10)
            }

        },
        updatePlayers: (state, action) => {
            let playerResponse = action.payload
            let playerArray = []

            playerResponse.forEach(player => {
                playerArray.push({
                    id: player.id,
                    playerName: player.playerName,
                    gameID: player.gameID
                })
            })

            state.players = playerArray
        },
        createNewShot: (state, action) => {
            state.isShotFormOpen = true
        },
        abortNewShot: (state,action) => {
            state.isShotFormOpen = false
        },
        addShot: (state, action) => {
            state.currentGame.shots.push(action.payload.hits)
            state.currentGame.bullseyes.push(action.payload.bullseyes)
            state.isShotFormOpen = false
        },
        editShot: (state, action) => {
            state.shotToEdit = action.payload
            state.showEditPopup = true
        },
        closeEditPopup: (state,action) => {
            state.shotToEdit = -1
            state.showEditPopup = false
        },
        updateShots: (state, action) => {
            if (action.payload.length > 0){
                let playerAlreadyInList = -1
                if (state.shots.length > 0){            
                    state.shots.forEach((playerShots, index) => {
                        if(playerShots[0].playerID === action.payload[0].playerID){
                            playerAlreadyInList = index
                        }
                    })
                }

                if (playerAlreadyInList === -1){
                    state.shots.push(action.payload)
                } 
                else {
                    state.shots[playerAlreadyInList]=action.payload
                }
            }
        },
        clearShots: (state, action) => {
            state.shots = []
        },
        setMenuState: (state, action) => {
            state.menuState = action.payload
        },
        setHref: (state, action) => {
            state.href = action.payload
        }

    }
})

export const selectCurrentGame = (state) => state.board.currentGame
export const selectIsGameSelected = (state) => state.board.isGameSelected
export const selectIsCreatingGame = (state) => state.board.isCreatingNewGame
export const selectAllGames = state => state.board.allGames
export const selectCurrentGameAllPlayers = state => state.board.players
export const selectCurrentGameShots = state => state.board.shots
export const selectCurrentGameRules = state => state.board.rules
export const selectIsShotFormOpen = state => state.board.isShotFormOpen
export const selectIsGamesListShown = state  => state.board.showGamesList
export const selectIsEditPopupOpen = state => state.board.showEditPopup
export const selectShotToEdit = state => state.board.shotToEdit
export const selectHref = state => state.board.href
export const selectMenuState = state => state.board.menuState

export const {
    updateGames,
    selectGame, 
    setupNewGame, 
    addNewGame, 
    abortNewGame, 
    updateGameRules,
    updatePlayers,
    createNewShot, 
    abortNewShot, 
    addShot, 
    editShot,
    closeEditPopup,
    updateShots,
    clearShots,
    setMenuState,
    setHref
} = boardSlice.actions

export default boardSlice.reducer;
