import React from 'react';
export const ShotTableHeader = (props) => {
    const orderPlayerItems = () => {
        let orderedPlayers = []
        const shots = props.currentShots || []
        const players = props.players || []
        if(shots === [])
        {
            return props.players
        }
        for(let i = 0; i<shots.length; i++)
        {
            for(let j = 0; j<players.length; j++)
            {
                if(shots[i][0].playerID === players[j].id)
                {
                    orderedPlayers.push(players[j])
                }
            }
        }
       // props.players
       // props.currenShots

        return orderedPlayers
    }


    if (props.players) {
        const columnWidth = 100/(props.players.length + 2) + "%"
        const columnStyle = {
            width: columnWidth
        }
        const playerNames = orderPlayerItems().map((player) => <th style={columnStyle}>{player.playerName}</th>)

        return <thead key={props.headerKey}>
            <tr><th style={columnStyle}></th>{playerNames}</tr>
        </thead>
    } else {
        return <thead></thead>
    }
}