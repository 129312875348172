export const defaultModeSettings = [
    {
        mode: "mode1",
        label: "Until First Hit",
        maxShots: 3,
        pointsPerHit: [16,10,4],
        pointsPerBullseye: 4
    },
    {
        mode: "mode0",
        label: "Fixed Shot Number",
        maxShots: 3,
        pointsPerHit: [3],
        pointsPerBullseye: 1
    }
]