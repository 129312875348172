import React from 'react';
import axios from 'axios'
import { useDispatch, useSelector, useStore } from "react-redux"
import { abortNewShot,  selectCurrentGameAllPlayers, selectCurrentGameRules, updateShots } from "../features/board/boardSlice"
import { nextPlayer, previousPlayer, resetShot, selectCurrentPlayerIndex} from "../features/shot/shotSlice"

export const ShotForm = (props) => {
    const dispatch = useDispatch()
    const store = useStore()

    const currentPlayer = useSelector(selectCurrentPlayerIndex)
    const allPlayers = useSelector(selectCurrentGameAllPlayers) || []
    const rules = useSelector(selectCurrentGameRules) || {}

    let hitAnimation = ""
    
    const onAddShotsToDB = () => {
        let nextShotIndex = store.getState().board.shots.length > 0 ? store.getState().board.shots[0].length : 0
        allPlayers.forEach((player, index) => {
        axios
            .post('https://archery.matscher.net:4001/shot/createshot', {
                hits: store.getState().shot.hits[index],
                bullseyes: store.getState().shot.bullseyes[index],
                shotNr: nextShotIndex,
                playerID: player.id,
                gameID: player.gameID,
            })
            .then(res => {
                fetchShots(player.gameID, player.id)
                console.log(res.data)
            })
            .catch(error => console.error(`There was an error creating the shot game: ${error}`))
        });
    }

    const fetchShots = async (gameID, playerID) => {
         
        axios
        .get('https://archery.matscher.net:4001/shot/allplayershots',{
            params:
                {
                    gameID: gameID,
                    playerID: playerID}
            })
        .then(response => {
            dispatch(updateShots(response.data))
            console.log(response)
        })
        .catch(err => console.error (`There was an error retrieving rule: ${err}`))      
    }

    const onHandlePreviousPlayer = e => {
        dispatch(previousPlayer())
        if (document.querySelector('input[name="numHits"]:checked'))
        { 
            document.querySelector('input[name="numHits"]:checked').checked = false
        }
        if (document.querySelector('input[name="numBullseyeHits"]:checked'))
        { 
            document.querySelector('input[name="numBullseyeHits"]:checked').checked = false
        }
    }
    const onHandleNextPlayer = e => {
        if (document.querySelector('input[name="numHits"]:checked'))
        {  
            let hitsCount = parseInt(document.querySelector('input[name="numHits"]:checked').value,10)   
            let bullseyeCount = 0 
            if ((hitsCount !== rules.maxShots)||(rules.gameMode === "mode0"))
            {
                if (document.querySelector('input[name="numBullseyeHits"]:checked'))
                {
                    bullseyeCount = parseInt(document.querySelector('input[name="numBullseyeHits"]:checked').value,10)
                    document.querySelector('input[name="numBullseyeHits"]:checked').checked = false
                } else {
                    document.getElementById("bullseyeButtons").className = "wrapper"
                    void document.getElementById("bullseyeButtons").offsetWidth
                    document.getElementById("bullseyeButtons").className = "wrapper shake-horizontal"
                    return
                }
            } else {
                if (document.querySelector('input[name="numBullseyeHits"]:checked'))
                {
                    document.querySelector('input[name="numBullseyeHits"]:checked').checked = false
                }     
            }
            dispatch(nextPlayer([hitsCount, bullseyeCount]))
            document.querySelector('input[name="numHits"]:checked').checked = false
            
        } else {
            document.getElementById("hitButtons").className = "wrapper"
            void document.getElementById("hitButtons").offsetWidth
            document.getElementById("hitButtons").className = "wrapper shake-horizontal"
        }

    }
    const onHandleAddShot = e => {
        if (document.querySelector('input[name="numHits"]:checked'))
        {
            let hitCount = parseInt(document.querySelector('input[name="numHits"]:checked').value,10)
            let bullseyeCount = 0 
            if ((hitCount !== rules.maxShots)||(rules.gameMode === "mode0"))
            {
                if (document.querySelector('input[name="numBullseyeHits"]:checked'))
                {
                    bullseyeCount = parseInt(document.querySelector('input[name="numBullseyeHits"]:checked').value,10)
                    document.querySelector('input[name="numBullseyeHits"]:checked').checked = false
                } else {
                    document.getElementById("bullseyeButtons").className = "wrapper"
                    void document.getElementById("bullseyeButtons").offsetWidth
                    document.getElementById("bullseyeButtons").className = "wrapper shake-horizontal"
                    return
                }
            } else {
                if (document.querySelector('input[name="numBullseyeHits"]:checked'))
                {
                    document.querySelector('input[name="numBullseyeHits"]:checked').checked = false
                }     
            }
            dispatch(nextPlayer([hitCount, bullseyeCount]))
            onAddShotsToDB()
            dispatch(abortNewShot())
            dispatch(resetShot())
            document.querySelector('input[name="numHits"]:checked').checked = false
        } else {
            document.getElementById("hitButtons").className = "wrapper"
            void document.getElementById("hitButtons").offsetWidth
            document.getElementById("hitButtons").className = "wrapper shake-horizontal"
        }
    }

    const onHandleAbortAddingShot = e => {
        dispatch(abortNewShot())
        dispatch(resetShot())
    }


    const hitButtons =  () => {
        let buttons = []
        let lables = []
        let buttontext = ""
        for (let i = 0; i<=rules.maxShots; i++){
            switch(rules.gameMode) {
                case "mode0": 
                    buttontext = i
                    break
                case "mode1":
                    buttontext = i !== rules.maxShots ? i + 1 : "No Hit" 
                    break
                default: 
                    break
            }


            buttons.push(
                <input type="radio" id={"option-"+i} name="numHits" value={i}/>

            )
            lables.push(
                <label htmlFor={"option-"+i} class={"option option-"+i}>
                    <div class="dot"></div>
                    <span>{buttontext}</span>
                </label>
            )
        }
        
        return <div id="hitButtons" class={"wrapper"} >{buttons}{lables}</div>
    }

    const bullseyeButtons = () => {
        let buttons = []
        let lables = []
        let bullseyeNumber = 0
        let buttontext = ""

        switch(rules.gameMode) {
            case "mode0": 
                bullseyeNumber = rules.maxShots
                break
            case "mode1":
                bullseyeNumber = 1
                break
            default: 
                break
            }

        for (let i = 0; i<=bullseyeNumber; i++){
            switch(rules.gameMode) {
                case "mode0": 
                    buttontext = i
                    break
                case "mode1":
                    buttontext = i !== 0 ? "Bullseye Hit" : "No Bullseye" 
                    break
                default: 
                    break
            }

            buttons.push(
                <input type="radio" id={"bullseye-"+i} name="numBullseyeHits" value={i}/>

            )
            lables.push(
                <label htmlFor={"bullseye-"+i} class={"option bullseye-"+i}>
                    <div class="dot"></div>
                    <span>{buttontext}</span>
                </label>
            )
        }
        
        return <div id="bullseyeButtons" class="wrapper">{buttons}{lables}</div>
    }

    let hitText = "test"
    switch(rules.gameMode) {
        case "mode0": 
            hitText = "Num Hits: "
            break
        case "mode1":
            hitText = "Hit at shot NR: "
            break
        default: 
            break
    }

    return <div className="popup" id="ShotForm" style={{display: props.isActive ? 'block' : 'none'}}>    
        <h1>
            <button className='Back-Button' id="Backbutton" onClick={onHandleAbortAddingShot}>
            </button>
            New Shot
        </h1>

        <h2 className="slide-in-right" key={allPlayers.length > 0 ? allPlayers[currentPlayer].playerName: "noPlayer"}>{allPlayers.length > 0 ? allPlayers[currentPlayer].playerName: ""}</h2>
        <br/>
        <label htmlFor="HitCount">{hitText}</label>
        <br/>
        {hitButtons()}
        <br/>
        <br/>
        <br/>
        <br/>
        <label htmlFor="BullsEyeCount">Bullseye: </label>
        <br/>
        {bullseyeButtons()}
        <br/>
        <br/>
        <br/>
        <br/>
        {(currentPlayer !== 0) && <button onClick={onHandlePreviousPlayer} class="wrapper">Previous Player</button>}
        {(currentPlayer !== allPlayers.length - 1) && <button onClick={onHandleNextPlayer} class="wrapper">Next Player</button>  }
        {(currentPlayer === allPlayers.length - 1) && <button onClick={onHandleAddShot} class="wrapper">Add Shot</button>}
    </div>
}