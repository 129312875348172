import React from 'react';
import { useDispatch} from 'react-redux'
import { setHref } from "../features/board/boardSlice"

export const GameItem = (props) => {
    const dispatch = useDispatch()
    const onSelectCurrentGame = () => {
        let queryString = window.location.search
        let urlParams = new URLSearchParams(queryString)
        urlParams.set("id", props.game.id)
        let newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
        dispatch(setHref(window.location.href))
    }

    const gameName = props.game.name !== "" ? props.game.name : props.game.players.map((player,i) => (i !== 0 ? "" : " ") + player) + " - " +props.game.date
    return<div>
        <button className='GameItem-Button'
            onClick={onSelectCurrentGame} >
            {gameName}
        </button>
    </div>
}