import React from "react";
import { ResponsiveBar } from '@nivo/bar'
import { useSelector } from "react-redux";
import { selectCurrentGameAllPlayers, selectCurrentGameRules, selectCurrentGameShots } from "../features/board/boardSlice";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const setupKeys = (rules) => {
    let keys = ['bullseye', 'no bullseye']

    return keys
}

const BullseyeGraph = (data, rules) => (
    <ResponsiveBar
        data={data}
        keys={setupKeys(rules)}
        indexBy="player"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'accent' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    '1.6'
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'player',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'bullseye / no bullseye',
            legendPosition: 'middle',
            legendOffset: -40,
            format: e => Math.floor(e) === e && e
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                itemTextColor: "#ffffff",
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        theme = {{           
            axis: {
            fontSize: "14px",
            tickColor: "#eee",
            ticks: {
              line: {
                stroke: "#555555"
              },
              text: {
                fill: "#ffffff"
              }
            },
            legend: {
              text: {
                fill: "#aaaaaa"
              }
            }
          },
          grid: {
            line: {
              stroke: "#555555"
            }
          },
        }}
        isInteractive={false}
        animate={false}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
    />
)

const createPlayerHitData = (shots,rules) => {
    let hitData = [0,0]

    if (shots)
    {
           shots.forEach(shot => {
        switch (rules.gameMode) {
            case "mode0":
                hitData[0] += shot.bullseyes
                hitData[1] += rules.maxShots - shot.bullseyes
                break
            case "mode1":
                hitData[0] += shot.bullseyes ? 1 : 0
                hitData[1] += (!shot.bullseyes && (shot.hits < rules.maxShots)) ? 1 : 0
                break
            default:
                break
            }
        })
    }

    return hitData
}

export const BullseyeChart = () => {
    const players = useSelector(selectCurrentGameAllPlayers)
    const shots = useSelector(selectCurrentGameShots) || []
    const rules = useSelector(selectCurrentGameRules) || {}

    let data = []
    const keys = setupKeys(rules)
    if (shots.length === players.length)
    {   
        let hitData = []
        players.forEach((player) => {
            let playerIndex = shots.findIndex(playerShot=> playerShot[0].playerID === player.id)
            hitData =createPlayerHitData(shots[playerIndex], rules)
            data.push({
                "player": player.playerName,
                [keys[0]]: hitData[0],
                [keys[1]]: hitData[1],
            })
        })
    }


    return <div style={{height: 500}}>
        {BullseyeGraph(data, rules)}
    </div>
    
}