import React from 'react';
import axios from 'axios'
import { useDispatch, useSelector, useStore } from "react-redux"
import { closeEditPopup,  selectCurrentGameAllPlayers, selectCurrentGameRules, selectShotToEdit, updateShots } from "../features/board/boardSlice"


export const EditForm = (props) => {
    const dispatch = useDispatch()
    const store = useStore()
    const shotToEdit = useSelector(selectShotToEdit)
    const allPlayers = useSelector(selectCurrentGameAllPlayers) || []
    const rules = useSelector(selectCurrentGameRules) || {}


    const updatePlayerName = () =>{
        let playerName = ""
        let shots = store.getState().board.shots
        shots.forEach((playerShot) => {
            playerShot.forEach((shot)=>{
                if(shot.id === parseInt(shotToEdit,10)){
                    allPlayers.forEach((player)=>{
                        if(player.id === shot.playerID){
                            playerName = player.playerName + " Shot NR:" + (shot.shotNr + 1)
                            document.getElementById("option-"+shot.hits+"-edit").checked = true
                            document.getElementById("bullseye-"+shot.bullseyes+"-edit").checked = true
                        }
                    })
                }
            })
        })
        return playerName
    }


    
    const onUpdateShotInDB= (updatedHits, updatedBullseyes) => {
        axios
            .post('https://archery.matscher.net:4001/shot/updateshot', {
                id: store.getState().board.shotToEdit,
                hits: updatedHits,
                bullseyes: updatedBullseyes,
            })
            .then(res => {
                allPlayers.forEach((player, index) => {
                    fetchShots(player.gameID, player.id)
                    console.log(res.data)
                })
            })
            .catch(error => console.error(`There was an error creating the shot game: ${error}`))

    }

    const fetchShots = async (gameID, playerID) => {
         
        axios
        .get('https://archery.matscher.net:4001/shot/allplayershots',{
            params:
                {
                    gameID: gameID,
                    playerID: playerID}
            })
        .then(response => {
            dispatch(updateShots(response.data))
            console.log(response)
        })
        .catch(err => console.error (`There was an error retrieving rule: ${err}`))      
    }

    const onApplyShotChanges = e => {
        if (document.querySelector('input[name="numHitsEdit"]:checked'))
        {
            let hitCount = parseInt(document.querySelector('input[name="numHitsEdit"]:checked').value,10)
            let bullseyeCount = 0 
            if ((hitCount !== rules.maxShots)||(rules.gameMode === "mode0"))
            {
                if (document.querySelector('input[name="numBullseyeHitsEdit"]:checked'))
                {
                    bullseyeCount = parseInt(document.querySelector('input[name="numBullseyeHitsEdit"]:checked').value,10)
                    document.querySelector('input[name="numBullseyeHitsEdit"]:checked').checked = false
                } else {
                    document.getElementById("bullseyeButtonsEdit").className = "wrapper"
                    void document.getElementById("bullseyeButtonsEdit").offsetWidth
                    document.getElementById("bullseyeButtonsEdit").className = "wrapper shake-horizontal"
                    return
                }
            } else {
                if (document.querySelector('input[name="numBullseyeHitsEdit"]:checked'))
                {
                    document.querySelector('input[name="numBullseyeHitsEdit"]:checked').checked = false
                }     
            }

            document.querySelector('input[name="numHitsEdit"]:checked').checked = false
            onUpdateShotInDB(hitCount, bullseyeCount)
            dispatch(closeEditPopup())
        } else {
            document.getElementById("hitButtonsEdit").className = "wrapper"
            void document.getElementById("hitButtonsEdit").offsetWidth
            document.getElementById("hitButtonsEdit").className = "wrapper shake-horizontal"
        }
    }

    const onHandleAbortEditingShot = e => {
        dispatch(closeEditPopup())
    }


    const hitButtons =  () => {
        let buttons = []
        let lables = []
        let buttontext = ""
        for (let i = 0; i<=rules.maxShots; i++){
            switch(rules.gameMode) {
                case "mode0": 
                    buttontext = i
                    break
                case "mode1":
                    buttontext = i !== rules.maxShots ? i + 1 : "No Hit" 
                    break
                default: 
                    break
            }


            buttons.push(
                <input type="radio" id={"option-"+i+"-edit"} name="numHitsEdit" value={i}/>

            )
            lables.push(
                <label htmlFor={"option-"+i+"-edit"} class={"option option-"+i+"-edit"}>
                    <div class="dot"></div>
                    <span>{buttontext}</span>
                </label>
            )
        }
        
        return <div id="hitButtonsEdit" class="wrapper">{buttons}{lables}</div>
    }

    const bullseyeButtons = () => {
        let buttons = []
        let lables = []
        let bullseyeNumber = 0
        let buttontext = ""

        switch(rules.gameMode) {
            case "mode0": 
                bullseyeNumber = rules.maxShots
                break
            case "mode1":
                bullseyeNumber = 1
                break
            default: 
                break
            }

        for (let i = 0; i<=bullseyeNumber; i++){
            switch(rules.gameMode) {
                case "mode0": 
                    buttontext = i
                    break
                case "mode1":
                    buttontext = i !== 0 ? "Bullseye Hit" : "No Bullseye" 
                    break
                default: 
                    break
            }

            buttons.push(
                <input type="radio" id={"bullseye-"+i+"-edit"} name="numBullseyeHitsEdit" value={i} />

            )
            lables.push(
                <label htmlFor={"bullseye-"+i+"-edit"} class={"option bullseye-"+i+"-edit"}>
                    <div class="dot"></div>
                    <span>{buttontext}</span>
                </label>
            )
        }
        
        return <div id="bullseyeButtonsEdit" class="wrapper">{buttons}{lables}</div>
    }

    let hitText = "test"
    switch(rules.gameMode) {
        case "mode0": 
            hitText = "Num Hits: "
            break
        case "mode1":
            hitText = "Hit at shot NR: "
            break
        default: 
            break
    }

    return <div className="popup" id="EditForm" style={{display: props.isActive ? 'block' : 'none'}}>    
        <h1>
            <button className='Back-Button' id="Backbutton" onClick={onHandleAbortEditingShot}>
            </button>
            Edit Shot
        </h1>

        <h2>{updatePlayerName()}</h2>
        <br/>
        <label htmlFor="HitCount">{hitText}</label>
        <br/>
        {hitButtons()}
        <br/>
        <br/>
        <br/>
        <br/>
        <label htmlFor="BullsEyeCount">Bullseye: </label>
        <br/>
        {bullseyeButtons()}
        <br/>
        <br/>
        <br/>
        <br/>
        <button onClick={onApplyShotChanges} class="wrapper">Apply Changes</button>
    </div>
}