import { createSlice } from '@reduxjs/toolkit'

export const menuBarSlice = createSlice({
    name: 'menuBar',
    initialState: {
        showDropDown: false,
    },
    reducers:{
        setIsDropDownVisible: (state, action) => {
            state.showDropDown = action.payload
        }

    }
})

export const selectIsDropDownVisible = state => state.menuBar.showDropDown

export const {
    setIsDropDownVisible,
} = menuBarSlice.actions

export default menuBarSlice.reducer;