import React from 'react';
import axios from 'axios'
import { useDispatch, useSelector} from "react-redux"
import { defaultModeSettings } from "../data/modeDefaults"
import { abortNewGame, addNewGame, selectGame } from "../features/board/boardSlice"
import { addPlayer, removePlayer, selectAllPlayers, selectCurrentDate, setName } from "../features/newGame/newGameSlice"
import { resetRules, selectBullseyePoints, selectGameMode, selectMaxShot, selectShotPoints, setBullseyePoints, setDefaultRules, setMaxShots, setShotPoints } from "../features/rules/rulesSlice"

export const NewGameForm = (props) => {
    const dispatch = useDispatch()

    const modeOptions = defaultModeSettings.map((modeSetting,i) => <option value={i} key={modeSetting.mode}>{modeSetting.label}</option>)

    const gameMode = useSelector(selectGameMode)
    const maxShots = useSelector(selectMaxShot)
    const pointsPerShot = useSelector(selectShotPoints)
    const pointsPerBullseye = useSelector(selectBullseyePoints)
    const players = useSelector(selectAllPlayers)
    const date = useSelector(selectCurrentDate)


    // event Handlers
    const handleDropDownChange = (e) => {
        const value = parseInt(e.target.value,10)
        dispatch(setDefaultRules(value ? value : 0))
        e.target.value = parseInt(e.target.value,10)
    }

    const handleMaxShotChange = (e) => {
        const value = parseInt(e.target.value,10)
        dispatch(setMaxShots(value ? value : 0))
        e.target.value = parseInt(e.target.value,10)
    }

    const handlePointsPerHitChange = (e) => {
        const value = parseInt(e.target.value,10)
        const index = parseInt(e.target.id,10)
        dispatch(setShotPoints([index ? index : 0, value ? value : 0]))
        e.target.value = parseInt(e.target.value,10)
    }

    const handlePointsPerBullseyeChange = (e) => {
        const value = parseInt(e.target.value,10)
        dispatch(setBullseyePoints(value ? value : 0))
        e.target.value = parseInt(e.target.value,10)
    }

    const handlePlayerDelete = e => {
        dispatch(removePlayer(parseInt(e.target.id,10)))
    }

    const handleGameNameChange = e => {
        dispatch(setName(e.target.value))
    }

    const handleAbortNewGame = e => {
        dispatch(abortNewGame())
        dispatch(resetRules())
    }

    const placeholderGameText = players.map((player, i) => (i !==0 ? " " : "") + player) + " | " + date

    const onSubmitForm = e => {

        let currentDate = new Date()
        currentDate = currentDate.getUTCFullYear() + '-' +
        ('00' + (currentDate.getUTCMonth()+1)).slice(-2) + '-' +
        ('00' + currentDate.getUTCDate()).slice(-2)
        // Send POST request to 'books/create' endpoint
        axios
            .post('https://archery.matscher.net:4001/games/creategame', {
                name: placeholderGameText,
                date: currentDate,
            })
            .then(res => {
                players.forEach(player => {
                    onAddPlayerToDB(player, res.data[0])
                });

                onAddRulesToDB(gameMode, maxShots, pointsPerShot, pointsPerBullseye, res.data[0])

                dispatch(addNewGame())
                dispatch(resetRules())
                props.onFetchGames()
                let queryString = window.location.search
                let urlParams = new URLSearchParams(queryString)
                urlParams.set("id", res.data[0])
                let newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();
                window.history.pushState(null, '', newRelativePathQuery);
                
            })
            .catch(error => console.error(`There was an error creating the ${placeholderGameText} game: ${error}`))
    }

    const onAddPlayerToDB = (player, gameID) => {
        axios
            .post('https://archery.matscher.net:4001/players/createplayer', {
                name: player,
                gameID: gameID,
            })
            .then(res => {
                console.log(res.data)
            })
            .catch(error => console.error(`There was an error creating the ${placeholderGameText} game: ${error}`))
    }

    const onAddRulesToDB = (gameMode, maxShots, normalPoints, bullseyePoints, gameID) => {
        // store the mode as integer
        let mode = 0
        switch(gameMode){
            case "mode0":
                mode = 0
                break
            case "mode1":
                mode = 1
                break
            default:
                break
        }

        // turn points arrays into strings
        let normalPointsString = ""
        normalPoints.forEach(points => {
            normalPointsString = normalPointsString === "" ? normalPointsString : normalPointsString + ";"
            normalPointsString = normalPointsString + points
        })

        let bullseyePointsString = "" + bullseyePoints

        axios
            .post('https://archery.matscher.net:4001/rules/createrule',{
                mode: mode,
                maxShot: maxShots,
                normalPoints: normalPointsString,
                bullseyePoints: bullseyePointsString,
                gameID: gameID
            })
            .then(res => {
                console.log(res.data)
            })
            .catch(error => console.error(`There was an error creating the ${placeholderGameText} game: ${error}`))
    }

    const onAddPlayer = e => {
        dispatch(addPlayer(document.getElementById("addPlayer").value))
        document.getElementById("addPlayer").value = ""
        document.getElementById("addPlayer").focus()
    }

    // setup rules list elements
    let settingsOptions = <div></div>
    switch (gameMode) {
        // rules list for fixed shot mode
        case "mode0":
            settingsOptions = 
            <div style={{width: "100%"}}>
                <br/>
                <label htmlFor="maxShotCount">Max Shots: </label>
                <input id="maxShotCount" type="number" onChange={handleMaxShotChange} value={maxShots}/>
                <br/>
                <label htmlFor="pointsPerHit">Points per Hit: </label>
                <input id="pointsPerHit" type="number" onChange={handlePointsPerHitChange} value={pointsPerShot[0]}/>
                <br/>
                <label htmlFor="pointsPerBullseye">Points per Bullseye: </label>
                <input id="pointsPerBullseye" type="number" onChange={handlePointsPerBullseyeChange} value={pointsPerBullseye}/>
            </div>
            break
        // rules list for until first shot mode
        case "mode1":
            let hitPointOptions = []

            for (let i = 0; i<maxShots; i++ ){
                hitPointOptions.push(<br/>)
                hitPointOptions.push(<label htmlFor={"pointsPerHit"+i}>Points for hit on shot {i + 1}: </label>)
                hitPointOptions.push(<input id={i} type="number" onChange={handlePointsPerHitChange} value={pointsPerShot[i]}/>)
            }

            settingsOptions = 
            <div>
                <label htmlFor="maxShotCount">Max Shots: </label>
                <input id="maxShotCount" type="number" onChange={handleMaxShotChange} value={maxShots}/>
                {hitPointOptions}
                <br/>
                <label htmlFor="pointsPerBullseye">Points per Bullseye: </label>
                <input id="pointsPerBullseye" type="number" onChange={handlePointsPerBullseyeChange} value={pointsPerBullseye}/>
            </div>
            break
        default:
            break
    }

    // setup player list
    const playerList = players.map((player, i) => <div key={player}>
        {player} <button id={i} onClick={handlePlayerDelete}>delete</button>
    </div>)

    // popup
    return <div className="popup" id="NewGameForm" style={{display: props.isActive ? 'block' : 'none'}}>
        <h1>
            <button className='Back-Button' id="Backbutton" onClick={handleAbortNewGame}>
            </button>
            Setup new Game
        </h1>
        <h2>Name</h2>
        <label htmlFor="gameName">Set Name: </label>
        <input name="gameName" id="gameName" placeholder={placeholderGameText} onChange={handleGameNameChange}></input>

        <h2>Rules</h2>
        <label htmlFor="gameMode">Game Mode: </label>
        <select name="gameMode" id="gameMode" onChange={handleDropDownChange}>
            {modeOptions}
        </select>
        {settingsOptions}
        
        <h2>Players</h2>
        <label htmlFor="addPlayer">Player Name </label>
        <input name="addPlayer" id="addPlayer"></input>
        <button onClick={onAddPlayer}> Add Player</button>
        <br/>
        {playerList}
        <br/>
        <br/>
        <br/>
        <button onClick={onSubmitForm}> Create New Game </button>
    </div>
}