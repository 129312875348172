import { configureStore } from "@reduxjs/toolkit";
import boardReducer from './features/board/boardSlice'
import newGameReducer from './features/newGame/newGameSlice'
import rulesSlice from "./features/rules/rulesSlice";
import shotSlice from "./features/shot/shotSlice";
import playerStatsReducer from "./features/playerStats/playerStatsSlice";
import menuBarReducer from "./features/MenuBar/menuBarSlice"


export const store = configureStore({
    reducer: {
        board: boardReducer,
        newGame: newGameReducer,
        rules: rulesSlice,
        shot: shotSlice,
        playerStats: playerStatsReducer,
        menuBar: menuBarReducer,
    }
})
