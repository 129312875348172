import React from "react";
import { ResponsiveLine } from '@nivo/line'
import { useSelector } from "react-redux";
import { selectCurrentGameAllPlayers, selectCurrentGameRules, selectCurrentGameShots } from "../features/board/boardSlice";

const PointsGraph = (data) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false
        }}
        yFormat=" >-.2f"
        curve="cardinal"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'shot',
            legendOffset: 36,
            legendPosition: 'middle',
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickColor: "rgb(1,1,1,1)",
            fill: "rgb(1,1,1,1)",
            legend: 'points',
            legendOffset: -40,
            legendPosition: 'middle',
        }}
        theme = {{           
            axis: {
            fontSize: "14px",
            tickColor: "#eee",
            ticks: {
              line: {
                stroke: "#555555"
              },
              text: {
                fill: "#ffffff"
              }
            },
            legend: {
              text: {
                fill: "#aaaaaa"
              }
            }
          },
          grid: {
            line: {
              stroke: "#555555"
            }
          },
          tooltip:{
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12
            },
        
        }}}
        colors={{ scheme: 'set2' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={false}
        areaBlendMode="multiply"
        enableCrosshair={false}
        useMesh={true}

        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 1,
                itemTextColor: "#ffffff",
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        motionConfig="default"
    />
)

const createPlayerShotData = (playerShotData, rules) => {
    let accumulatedPoints = 0
    let data = []
    if(playerShotData !== undefined)
    {    
        playerShotData.forEach(shot => {
            switch (rules.gameMode) {
                case "mode0":
                    accumulatedPoints += shot.hits * rules.normalPoints[0]
                    accumulatedPoints += shot.bullseyes * rules.bullseyePoints
                    break
                case "mode1":
                    accumulatedPoints += shot.hits < rules.maxShots ? rules.normalPoints[shot.hits] : 0
                    accumulatedPoints += shot.bullseyes ? rules.bullseyePoints : 0
                    break
                default:
                    break
            } 
            data.push({
                "x": shot.shotNr + 1,
                "y": accumulatedPoints
            })
        })}
    return data
}

export const PointsChart = () => {
    const players = useSelector(selectCurrentGameAllPlayers)
    const shots = useSelector(selectCurrentGameShots) || []
    const rules = useSelector(selectCurrentGameRules) || {}

    let data = []
    if (shots.length === players.length)
    {    
        players.forEach((player) => {
            let playerIndex = shots.findIndex(playerShot=> playerShot[0].playerID === player.id)
            data.push({
                "id": player.playerName,
                "data": createPlayerShotData(shots[playerIndex],rules)
            })
        })
    }


    return <div style={{height: 500}}>
        {PointsGraph(data)}
    </div>
    
}