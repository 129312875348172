import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import { useSelector } from 'react-redux';
import { selectFilteredPlayerData } from '../features/playerStats/playerStatsSlice';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const StatsAccuracyGraph = (data) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 20, right: 60, bottom: 80, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: '0',
            max: '100',
            stacked: false,
            reverse: false
        }}
        yFormat=" >-.2f"
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Game',
            legendOffset: 36,
            legendPosition: 'middle',
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickColor: "rgb(1,1,1,1)",
            fill: "rgb(1,1,1,1)",
            legend: 'Accuracy (%)',
            legendOffset: -40,
            legendPosition: 'middle',
        }}
        theme = {{           
            axis: {
            fontSize: "14px",
            tickColor: "#eee",
            ticks: {
              line: {
                stroke: "#555555"
              },
              text: {
                fill: "#ffffff"
              }
            },
            legend: {
              text: {
                fill: "#aaaaaa"
              }
            }
          },
          grid: {
            line: {
              stroke: "#555555"
            }
          },
          tooltip:{
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12
            },
        
        }}}
        colors={{ scheme: 'set2' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[]}
        motionConfig="default"
    />
)

const createAccuracyData = (inputData) => {
    let data = []

    data = inputData.map((item, index) => {
        let numShots = item.shots.reduce((prev, current) => {
                return prev + Math.min(current.hits,2)+1
            }, 0)
        let numHits = item.shots.reduce((prev, current) => {
            return prev + (current.hits < 3 ? 1 : 0)
        }, 0)
        let accuracy = 0
        if(numShots !== 0)
            accuracy = numHits/numShots * 100

        return {
            "x": index + 1,
            "y": accuracy
        }

    })
    
    return data
}

const createTrendLineData = (inputData) => {
  let data = []

  let n = inputData.length
  let a = n * inputData.reduce((prev, curr) => { return prev + curr.x * curr.y }, 0)
  let b = inputData.reduce((prev, curr) => { return prev + curr.x}, 0) *
          inputData.reduce((prev, curr) => { return prev + curr.y}, 0)
  let c = n * inputData.reduce((prev, curr) => { return prev + curr.x * curr.x}, 0) 
  let d = Math.pow(inputData.reduce((prev, curr) => { return prev + curr.x}, 0) ,2)
  let slope = (a-b)/(c-d)
  let e = inputData.reduce((prev, curr) => { return prev + curr.y}, 0)
  let f = slope * inputData.reduce((prev, curr) => { return prev + curr.x}, 0)
  let offset = (e-f)/n

  data.push({
    "x": 1,
    "y": slope + offset
  })
  data.push({
    "x": n,
    "y": slope*(n+1) + offset
  })

  return data
}

export const StatsAccuracyChart = () => {
  const filteredData = useSelector(selectFilteredPlayerData)
  let data = []
  let dataPoints = createAccuracyData(filteredData)
  let trendlineDataPoints = createTrendLineData(dataPoints)
  data.push({
    "id": "Player",
    "data": dataPoints
  })
  data.push({
    "id": "Trend",
    "data": trendlineDataPoints
  })


  return <div style={{height: "500px"}}>
      {StatsAccuracyGraph(data)}
  </div>
}