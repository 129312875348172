import './App.css';
import React, { useEffect } from 'react';
import {GameList} from './components/GameList'
import {CurrentGame} from './components/CurrentGame'
import { NewGameForm } from './components/NewGameForm';
import { PlayerStats } from './components/PlayerStats';
import { useDispatch, useSelector } from 'react-redux';
import { clearShots, selectAllGames, selectCurrentGame, selectGame, selectHref, setupNewGame, selectIsCreatingGame, selectIsEditPopupOpen, selectIsGamesListShown, selectIsShotFormOpen, selectMenuState, setMenuState, updateGameRules, updateGames, updatePlayers, updateShots } from './features/board/boardSlice';
import { ShotForm } from './components/ShotForm';
import Axios from 'axios';
import { MenuBar } from './components/MenuBar';
import { EditForm } from './components/EditShotForm';
import { setHref } from "./features/board/boardSlice";
import { setIsDropDownVisible } from './features/MenuBar/menuBarSlice';
import { selectAllPlayers, selectCurrentData, selectCurrentStatsPlayer, setSelectedPlayer, updateAllPlayers, addPlayerShotData, clearPlayerShotData } from './features/playerStats/playerStatsSlice';

function App() {

  const dispatch = useDispatch()
  const href = useSelector(selectHref)
  const games = useSelector(selectAllGames)
  const isGameCreationActive = useSelector(selectIsCreatingGame)
  const isShotFormActive = useSelector(selectIsShotFormOpen)
  const showGamesList = useSelector(selectIsGamesListShown)
  const isEditShotFormActive = useSelector(selectIsEditPopupOpen)
  const currentGame = useSelector(selectCurrentGame) 
  const currentMenuState = useSelector(selectMenuState)
  const allPlayers = useSelector(selectAllPlayers)
  const currentStatsPlayer = useSelector(selectCurrentStatsPlayer)


  useEffect(()=>{
    fetchGames()
  },[,showGamesList])

  useEffect(()=>{
    const intervalID = setInterval(() => {
      fetchGames()
    },60000)
    return () => clearInterval(intervalID)
  })


  useEffect(()=>{
    getURLparams()
  },[href, games])

  useEffect(()=>{
    fetchAllShotsforPlayer(currentStatsPlayer)
  }, [,currentStatsPlayer, allPlayers])

  // Fetch all books
  const fetchGames = async () => {
  // Send GET request to 'books/all' endpoint
  Axios
    .get('https://archery.matscher.net:4001/games/allgames')
    .then(response => {
      // Update the books state
      if (response.data) {
          let gamesList = []
          response.data.forEach(element => {
              gamesList.push({
                  id: element.id,
                  name: element.name,
                  date: element.date
              })
          
          })
          dispatch(updateGames(gamesList))
          return
      };
      dispatch(updateGames([]))

    })
    .catch(error => console.error(`There was an error retrieving the game list: ${error}`))
  }


  const getURLparams = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    
    console.log(urlParams.get('id'))

    games.forEach((game) =>{
      if (game.id === parseInt(urlParams.get('id'),10))
      { 
        dispatch(selectGame(game))
        fetchGameRules(game.id)
        fetchPlayers(game.id)
      }
    })
    if(urlParams.has('PlayerStats'))
    {
      fetchAllPlayers()
      let selectedPlayerString = urlParams.get('PlayerStats')
      dispatch(setSelectedPlayer(selectedPlayerString))
      dispatch(setMenuState("ShowPlayerStats"))
    }
    else
    {
      dispatch(setSelectedPlayer(""))
    }
  }

  const fetchGameRules = async (gameID) => {
    Axios
        .get('https://archery.matscher.net:4001/rules/specificrule',{
            params:
                {gameID: gameID}
            })
        .then(response => {
            console.log(response)
            dispatch(updateGameRules(response.data))
        })
        .catch(err => console.error (`There was an error retrieving rule: ${err}`))
}

  const fetchPlayers = async (gameID) => {
      Axios
      .get('https://archery.matscher.net:4001/players/playersingame',{
          params:
              {gameID: gameID}
          })
      .then(response => {
          console.log(response)
          dispatch(updatePlayers(response.data))
          dispatch(clearShots())
          response.data.forEach(player => {
              fetchShots(gameID, player.id)
          })
          
      })
      .catch(err => console.error (`There was an error retrieving rule: ${err}`))       
  }

  const fetchAllPlayers = async () => {
    Axios
    .get('https://archery.matscher.net:4001/players/allplayers')
    .then(response => {
      console.log(response)
      dispatch(updateAllPlayers(response.data))
    })
  }


  const fetchShots = async (gameID, playerID) => {
    Axios
    .get('https://archery.matscher.net:4001/shot/allplayershots',{
        params:
            {
                gameID: gameID,
                playerID: playerID}
        })
    .then(response => {
        dispatch(updateShots(response.data))
        console.log(response)
    })
    .catch(err => console.error (`There was an error retrieving rule: ${err}`))       
  }

  const fetchAllShotsforPlayer = async (playerName) => {
    dispatch(clearPlayerShotData())
    let selectedPlayerData = allPlayers.find((player) => {
      return currentStatsPlayer === player.playerName
    })
    if (selectedPlayerData)
    {    
      selectedPlayerData.id.forEach((playerId, index) => {
      Axios
      .get('https://archery.matscher.net:4001/shot/allplayershots',{
          params:
              {
                  gameID: selectedPlayerData.gameID[index],
                  playerID: playerId}
          })
      .then(response => {
        dispatch(addPlayerShotData({
          gameID: selectedPlayerData.gameID[index],
          shots: response.data
        }))
        console.log(response)
      })
      .catch(err => console.error (`There was an error retrieving rule: ${err}`)) 
    })}
  }


  const handleOnShowGameList = () => {
    let newRelativePathQuery = window.location.pathname;
    window.history.pushState(null, '', newRelativePathQuery);
    dispatch(setHref(window.location.href))
    dispatch(setMenuState("ShowGameList"))
    dispatch(setIsDropDownVisible(false))
  }

  const handleOnShowPlayerStats = () => {
    let newRelativePathQuery = window.location.pathname + "?PlayerStats";
    window.history.pushState(null, '', newRelativePathQuery);
    dispatch(setHref(window.location.href))
    dispatch(setMenuState("ShowPlayerStats"))
    dispatch(setIsDropDownVisible(false))
  }

  const handleOnCreateNewGame = () => {
    dispatch(setupNewGame({}))
    dispatch(setIsDropDownVisible(false))
  }

  return (
    <div className="Background">
      <div className="App">
        {(currentMenuState === "ShowGameList")  && !isGameCreationActive && !isShotFormActive && !isEditShotFormActive && <GameList />}
        {(currentMenuState === "ShowGame") && !isGameCreationActive && !isShotFormActive && !isEditShotFormActive && <CurrentGame />} 
        {(currentMenuState === "ShowPlayerStats") && !isGameCreationActive && !isShotFormActive && !isEditShotFormActive && <PlayerStats />} 
      </div>
      <NewGameForm isActive={isGameCreationActive} onFetchGames={fetchGames}/>
      <ShotForm isActive={isShotFormActive}/>
      <EditForm isActive={isEditShotFormActive}/>
      <MenuBar 
        onShowGamesList={handleOnShowGameList} 
        onShowPlayerStats={handleOnShowPlayerStats} 
        onCreateNewGame={handleOnCreateNewGame}
      />
    </div>
  );
}

export default App;
