import React from 'react';
export const AccuracyFooter = (props) => {
    if (props.shot) {
        const columnWidth = 100/(props.shot.length + 2) + "%"
        const columnStyle = {
            width: columnWidth
        }

        const pointsSum = props.shot.map((playerShot) => {

            let hits = 0
            let shots = 0
            let bullseyes = 0
            let hitAtCounter = 0

            playerShot.forEach(shot => {
                switch (props.rules.gameMode) {
                    case "mode0":
                        hits += shot.hits
                        shots += props.rules.maxShots
                        bullseyes += shot.bullseyes
                        break
                    case "mode1":
                        hitAtCounter += (props.rules.maxShots - shot.hits)
                        hits += shot.hits < props.rules.maxShots ? 1 : 0
                        shots += Math.min(Math.max(shot.hits + 1, 0), props.rules.maxShots)
                        bullseyes += shot.bullseyes
                        break
                    default:
                        break
                }
            })
            
            let tableValue = ""
            switch(props.accMode)
            {
                case "ShotAcc":
                    let accuracy = hits / shots * 100
                    tableValue = accuracy.toFixed(2) + "%" + " (" + hits + "/" + shots + ")"
                    break
                case "AverageHitAt":    
                    let averageShotsPerHit = shots / hits
                    tableValue = averageShotsPerHit.toFixed(2)
                    break
                case "OldAcc":    
                    let averageHitPercentage = hitAtCounter / (playerShot.length * props.rules.maxShots)

                    tableValue = (averageHitPercentage * 100).toFixed(1) + "% (" + hitAtCounter.toFixed(0) + "/" + playerShot.length * props.rules.maxShots+")"
                    break
                case "HitsPerBullseye":
                    let averageHitsPerBullseye = bullseyes/hits
                    tableValue = (averageHitsPerBullseye * 100).toFixed(1) + "% (" + bullseyes.toFixed(0) + "/" + hits.toFixed(0) + ")"
                    break
                default:
                    break
            }
            return <td>{tableValue}</td>
        })


        let footerText = ""
        switch(props.accMode)
        {
            case "ShotAcc":
                footerText = "Shot acc"
                break
            case "AverageHitAt":
                footerText = "Avg shots per hit"
                break
            case "OldAcc":
                footerText = "Avg target acc (old)"
                break
            case "HitsPerBullseye":
                footerText = "Bullseye percentage"
               break
            default:
                break
        }
        return <tfoot key={props.headerKey}>
            <tr><th style={columnStyle}>{footerText}</th>{pointsSum}</tr>
        </tfoot>
    } else {
        return <tfoot></tfoot>
    }
}