import React from 'react';
import { useDispatch} from 'react-redux'
import { setSessionCounterFilter } from '../features/playerStats/playerStatsSlice';



export const SessionCounterFilterItem = (props) => {
    const dispatch = useDispatch()

    const onSelectFilter = () => {
                dispatch(setSessionCounterFilter(props.filterOption)) 
            }

    const filterName = props.filterOption !== 0 ? "Last " + props.filterOption : "All"
    let buttonClass = props.filterOption === props.selectedOption ? 'PlayerItem-Button-Selected' : 'PlayerItem-Button'
    
    return<div>
        <button className={buttonClass} onClick={onSelectFilter} style={{width: "23%"}}>
            {filterName}
        </button>
    </div>
}