import { createSlice } from '@reduxjs/toolkit'

export const newGameSlice = createSlice({
    name: 'newGame',
    initialState: {
        name: '',
        date: new Date(Date.now()).toLocaleDateString('en-GB'),
        rules: {},
        players: [],
        shots: [],
        bullseyes: []
    },
    reducers:{
        resetNewGame: (state,action) => {
            return {...this.initialState}
        },
        addPlayer:(state,action) => {
            state.players.push(action.payload)
        },
        removePlayer: (state,action) => {
            state.players = state.players.filter((player,i) => i !== action.payload)
        },
        setName:(state,action) => {
            state.name = action.payload
        },
        setRules: (state,action) => {
            state.rules = action.payload
        }

    }
})

export const selectCurrentDate = (state) => state.newGame.date
export const selectAllPlayers = (state) => state.newGame.players
export const selectNewGame = (state) => state.newGame
 
export const {resetNewGame, addPlayer, removePlayer, setName, setRules} = newGameSlice.actions
export default newGameSlice.reducer