import React from 'react';
export const ShotTableFooter = (props) => {
    if (props.shot) {
        const columnWidth = 100/(props.shot.length + 2) + "%"
        const columnStyle = {
            width: columnWidth
        }

        const pointsSum = props.shot.map((playerShot) => {
            let points = 0
            let hitPoints = 0
            let bullseyePoints = 0
            playerShot.forEach(shot => {
                switch (props.rules.gameMode) {
                    case "mode0":
                        hitPoints = shot.hits * props.rules.normalPoints[0]
                        bullseyePoints = shot.bullseyes * props.rules.bullseyePoints
                        points += hitPoints + bullseyePoints
                        break
                    case "mode1":
                        hitPoints = shot.hits < props.rules.maxShots ? props.rules.normalPoints[shot.hits] : 0
                        bullseyePoints = shot.bullseyes ? props.rules.bullseyePoints : 0
                        points += hitPoints + bullseyePoints
                        break
                    default:
                        break
                }
            })
            return <td>{points}</td>
        })

        return <tfoot key={props.headerKey}>
            <tr><th style={columnStyle}>Points</th>{pointsSum}</tr>
        </tfoot>
    } else {
        return <tfoot></tfoot>
    }
}