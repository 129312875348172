import React from 'react';
import { useDispatch, useSelector} from 'react-redux'
import { selectAllPlayers, selectCurrentData, selectCurrentStatsPlayer, selectSessionCountFilter, setFilteredPlayerData } from '../features/playerStats/playerStatsSlice';
import { PlayerItem } from './PlayerItem'
import { SessionCounterFilterItem } from './SessionCounterFilterItem';
import { StatsAccuracyChart } from './StatsAccuracyChart';
import { StatsShotsPerHitChart } from './StatsShotsPerHitChart';

export const PlayerStats = (props) => {

    const dispatch = useDispatch()
    const allPlayers = useSelector(selectAllPlayers)
    const selectedPlayer = useSelector(selectCurrentStatsPlayer)
    const selectedPlayerData = useSelector(selectCurrentData)
    const SessionCountFilter = useSelector(selectSessionCountFilter)

    const PlayerItems = allPlayers.map((item) => <PlayerItem player={item} key={"Player"+item.playerName} selectedPlayer={selectedPlayer}/>)

    const SessionFilterOptions = [0, 20, 10, 5]
    const FilterItems = SessionFilterOptions.map((item) => <SessionCounterFilterItem filterOption={item} selectedOption={SessionCountFilter} key={"SessionFilter"+item}/>)
    const slicedPlayerData = selectedPlayerData.slice(SessionCountFilter * -1)
    dispatch(setFilteredPlayerData(slicedPlayerData))

    let numGames = slicedPlayerData.length
    let numShots = slicedPlayerData.reduce((x,y) => {
        return x + y.shots.reduce((prev, current) => {
            return prev + Math.min(current.hits,2)+1
        }, 0)
    }, 0)
    let numTargets = slicedPlayerData.reduce((prev, current) =>{
        return prev + current.shots.length
    }, 0)

    let numPaddedHits = slicedPlayerData.reduce((x,y) => {
        return x + y.shots.reduce((prev, current) => {
            return prev +  3 - current.hits
        }, 0)
    }, 0)
    let numHits = slicedPlayerData.reduce((x,y) => {
        return x + y.shots.reduce((prev, current) => {
            return prev + (current.hits < 3 ? 1 : 0)
        }, 0)
    }, 0)
    let numBullseyes = slicedPlayerData.reduce((x,y) => {
        return x + y.shots.reduce((prev, current) => {
            return prev + current.bullseyes
        }, 0)
    }, 0)
    let accuracy = numHits/numShots * 100
    let shotsPerHit = numShots/numHits
    let hitsPerBullseye = numHits/numBullseyes
    let targetAccuracy = numPaddedHits/(numTargets * 3) * 100



    return<div className='PlayerStats-View'>
        Player Stats
        <div style={{width: "100%", float: "left"}}>
            {PlayerItems}
        </div>
        {(selectedPlayer !== "") && <div style={{fontSize:"large", width:"100%"}}>
            Filter
            {FilterItems}

            <div style={{width: "100%", float: "left"}}> Historical data</div>
            <div style={{justifyContent: "center", marginBottom: "100px"}}>
                <table style={{width:"100%", float: "left"}}>
                    <tr>
                        <td>Num Games<br/>{numGames}</td>
                        <td>Shots<br/>{numShots}</td>
                    </tr>
                    <tr>
                        <td>Hits<br/>{numHits}</td>
                        <td>Bullseyes<br/>{numBullseyes}</td>
                    </tr>
                    <tr>
                        <td>Accuracy<br/>{accuracy.toFixed(2) + "%"}</td>
                        <td>Shots per Hit<br/>{shotsPerHit.toFixed(2)}</td>
                    </tr> 
                    <tr>
                        <td>Target accuracy<br/>{targetAccuracy.toFixed(2) + "%"}</td>
                        <td>Hits per bullseye<br/>{hitsPerBullseye.toFixed(2)}</td>
                    </tr> 
                </table>
            </div>
            Graphs 
            <br/>
            <br/>
            Accuracy (Higher is better)
            <StatsAccuracyChart/>
            Shots per Hit (Lower is better)
            <StatsShotsPerHitChart/>
        </div>}
    </div>
}