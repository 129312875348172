import React from 'react';
export const ShotTableRow = (props) => {
    const shotRow = props.shot.map((playerShot, i) => {
        let points = 0
        let hitPoints = 0
        let bullseyePoints = 0
        let accessKeyId = -1

        const onEditShot = (e) => {
            props.editFunction(e.currentTarget.getAttribute("accessKey"))
        }

        switch (props.rules.gameMode) {
            case "mode0":
                if (playerShot.length > props.rowKey){
                    hitPoints = playerShot[props.rowKey].hits * props.rules.normalPoints[0]
                    bullseyePoints = playerShot[props.rowKey].bullseyes * props.rules.bullseyePoints
                    points = hitPoints + bullseyePoints
                    accessKeyId = playerShot[props.rowKey].id
                }
                break
            case "mode1":
                if (playerShot.length > props.rowKey){
                    hitPoints = playerShot[props.rowKey].hits < props.rules.maxShots ? props.rules.normalPoints[playerShot[props.rowKey].hits] : 0
                    bullseyePoints = playerShot[props.rowKey].bullseyes ? props.rules.bullseyePoints : 0
                    points = hitPoints + bullseyePoints
                    accessKeyId = playerShot[props.rowKey].id
                }
                break
            default:
                break
        }
        return <td accessKey={accessKeyId} onClick={onEditShot}>{points}</td>
    })
    return <tbody key={"ShotRow" + props.rowKey}>
        <tr><td>{props.rowKey + 1}</td>{shotRow}</tr>
    </tbody>
}