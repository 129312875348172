import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDropDownVisible, setIsDropDownVisible } from '../features/MenuBar/menuBarSlice';



export const MenuBar = (props) => {
    const dispatch = useDispatch()
    const currentDropDownVisibility = useSelector(selectIsDropDownVisible)
    
    const handleDropDownToggle = () => {
        dispatch(setIsDropDownVisible(!currentDropDownVisibility))
    }

    return <div className='MenuBar-Header'>
        <div>
            <div className='MenuBar-Text'>
                Manus Archery Tracker
            </div>
            <button onClick={handleDropDownToggle} className='BurgerMenu-Button'></button> 
        </div>
        <div className='MenuBar-BottomLine'></div>
        {
            currentDropDownVisibility && <div className='DropDown-Background'>
                <button className='NewGame-Button' onClick={props.onCreateNewGame}>New Game</button>
                <button className='GameItem-Button' onClick={props.onShowGamesList}>Game List</button>
                <button className='GameItem-Button' onClick={props.onShowPlayerStats}>Player Stats</button>
            </div>
        }
    </div>
}