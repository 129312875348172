import { createSlice } from "@reduxjs/toolkit";
import { defaultModeSettings } from "../../data/modeDefaults";

const initState = defaultModeSettings[0]


export const rulesSlice = createSlice({
    name: 'rules',
    initialState: initState,
    reducers:{
        resetRules: (state) => {
            return initState
        },
        setDefaultRules: (state, action) => {
            return defaultModeSettings[action.payload]
        },
        setMaxShots: (state,action) => {
            let clampedInput = Math.min(Math.max(action.payload, 0), 100)
            let extendedPointsPerHit = []
            for (let i = 0; i<clampedInput; i++) {
                extendedPointsPerHit.push(i < state.pointsPerHit.length ? state.pointsPerHit[i] : 0)
            }
            
            return {
                ...state,
                maxShots: clampedInput,
                pointsPerHit: extendedPointsPerHit
            }
        }, 
        setShotPoints: (state,action) => {
            return {
                ...state,
                pointsPerHit: state.pointsPerHit.map((points, i) => i === action.payload[0]? action.payload[1] : points)
            }
        },
        setBullseyePoints: (state,action) => {
            return {
                ...state,
                pointsPerBullseye: action.payload
            }
        }

    }
})

export const selectGameMode = (state) => state.rules.mode
export const selectMaxShot = (state) => state.rules.maxShots
export const selectShotPoints = (state) => state.rules.pointsPerHit
export const selectBullseyePoints = (state) => state.rules.pointsPerBullseye
export const selectGameRules = (state) => state.rules

export const {resetRules, setDefaultRules, setMaxShots, setShotPoints, setBullseyePoints} = rulesSlice.actions
export default rulesSlice.reducer