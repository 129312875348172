import React from 'react';
import { GameItem } from "./GameItem"
import { NewGameItem } from "./NewGameItem"
import { selectAllGames} from "../features/board/boardSlice"
import { useSelector } from "react-redux";

export const GameList = (props) => {
    const gameData = useSelector(selectAllGames);
    const gameItems = gameData.map((item) => <GameItem game={item} key={"Game"+item.id}/>)

    return <div className='Game-List'>
        Game List
        {gameItems.reverse()}
    </div>
}