import { createSlice } from '@reduxjs/toolkit'

export const playerStatsSlice = createSlice({
    name: 'playerStats',
    initialState: {
        playerIds: [],
        selectedPlayer: "",
        SelectedPlayerShots: [],
        SessionCountFilter: 0,
        FilteredPlayerData:[],
    },
    reducers:{
        updateAllPlayers: (state, action) => {
            let playerResponse = action.payload
            let playerArray = []

            playerResponse.forEach(player => {

                let existingItem = playerArray.find(existingPlayer => {
                    return player.playerName.trim().toLowerCase() == existingPlayer.playerName.trim().toLowerCase()
                })


                if(existingItem)
                {
                    existingItem.id.push(player.id)
                    existingItem.gameID.push(player.gameID)
                }
                else
                {
                    let trimName = player.playerName.trim()
                    let capitalizedTrimmedName = trimName.charAt(0).toUpperCase() + trimName.slice(1)
                    playerArray.push({
                        id: [player.id],
                        playerName: capitalizedTrimmedName,
                        gameID: [player.gameID]
                    })
                }
            })
            playerArray.sort((left, right) => {
                if(right.id.length === left.id.length)
                {
                    if(left.playerName < right.playerName)
                        return -1
                    if(left.playerName > right.playerName)
                        return 1
                    return 0
                }
                return right.id.length - left.id.length
            })
            state.playerIds = playerArray
        },
        setSelectedPlayer: (state, action) => {
            state.selectedPlayer = action.payload
        },
        clearPlayerShotData: (state, action) => {
            state.SelectedPlayerShots = []
        },
        addPlayerShotData: (state, action) => {
            let shotAlreadyExisting = state.SelectedPlayerShots.find(trackedShot => {
                return trackedShot.gameID === action.payload.gameID
            })
            if(!shotAlreadyExisting)
            {            
                state.SelectedPlayerShots.push(action.payload)
                state.SelectedPlayerShots.sort((left, right) => {
                    return left.gameID - right.gameID
            })}
        },
        setSessionCounterFilter: (state, action) => {
            state.SessionCountFilter = action.payload
        },
        setFilteredPlayerData: (state, action) => {
            state.FilteredPlayerData = action.payload
        },

    }
})

export const selectAllPlayers = state => state.playerStats.playerIds
export const selectCurrentStatsPlayer = state => state.playerStats.selectedPlayer
export const selectCurrentData = state => state.playerStats.SelectedPlayerShots
export const selectSessionCountFilter = state => state.playerStats.SessionCountFilter
export const selectFilteredPlayerData = state => state.playerStats.FilteredPlayerData

export const {
    updateAllPlayers,
    setSelectedPlayer,
    clearPlayerShotData,
    addPlayerShotData,
    setSessionCounterFilter,
    setFilteredPlayerData
} = playerStatsSlice.actions

export default playerStatsSlice.reducer;