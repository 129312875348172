import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { createNewShot, editShot, selectCurrentGame, selectCurrentGameAllPlayers, selectCurrentGameRules, selectCurrentGameShots, selectIsCreatingGame, selectIsGameSelected } from "../features/board/boardSlice"
import { AccuracyFooter } from './AccuracyFooter'
import { BullseyeChart } from './BullseyeChart'
import { HitsChart } from './HitsChart'
import { PointsChart } from './PointsChart'
import { ShotTableFooter } from './ShotTableFooter'
import { ShotTableHeader } from "./ShotTableHeader"
import { ShotTableRow } from "./ShotTableRow"

export const CurrentGame = (props) => {
    const dispatch = useDispatch()

    const currentGameData = useSelector(selectCurrentGame)
    const isCreatingGame = useSelector(selectIsCreatingGame)
    const isGameSelected = useSelector(selectIsGameSelected)
    const currentPlayers = useSelector(selectCurrentGameAllPlayers)
    const currentShots = useSelector(selectCurrentGameShots) || []
    const currentRules = useSelector(selectCurrentGameRules)

    const tableVisible = currentShots.length > 0
    const onHandleAddShot = (e) => {
        dispatch(createNewShot())
    }

    const onEditShot = (shotID) => {
        dispatch(editShot(shotID))
    }

    const createShotRows = (shot, i) => {
        let rows = []

        if (currentShots.length > 0){
            for (let i = 0; i< currentShots[0].length; i++) {
                rows.push(<ShotTableRow shot={currentShots} rules={currentRules} rowKey={i} editFunction={onEditShot}/>)
            }
        }
        return rows
    }
    const shotRows = currentShots ? createShotRows() : <tbody></tbody>
    

    return <div className='Game-View'>
        {isGameSelected ? currentGameData.name : ( isCreatingGame ? "Create New Game" : "Select Game" )}
        {tableVisible && <table>
            <ShotTableHeader players={currentPlayers} currentShots={currentShots} headerKey="header"/>
            {shotRows}
            <ShotTableFooter shot={currentShots} rules={currentRules} footerKey="shots"/>
            <AccuracyFooter shot={currentShots} rules={currentRules} accMode="ShotAcc" footerKey="accuracy1"/>
            <AccuracyFooter shot={currentShots} rules={currentRules} accMode="OldAcc" footerKey="accuracy2"/>
            <AccuracyFooter shot={currentShots} rules={currentRules} accMode="HitsPerBullseye" footerKey="accuracy4"/>
            <AccuracyFooter shot={currentShots} rules={currentRules} accMode="AverageHitAt" footerKey="accuracy3"/>
        </table>}
        {tableVisible && <PointsChart/>}
        {tableVisible && <HitsChart/>}
        {tableVisible && <BullseyeChart/>}
        <div className = 'AddShot_TopLine'></div>
        <div className = 'AddShot-Footer'>
            {isGameSelected && <button onClick={onHandleAddShot} class={'wrapper'}>Add Shot</button>}
        </div>
    </div>
}