import { createSlice } from "@reduxjs/toolkit"

const initState = {
    currentPlayer: 0,
    hits: [],
    bullseyes: []
}

export const shotSlice = createSlice({
    name: 'shot',
    initialState: initState,
    reducers:{
        resetShot: () => {
            return initState
        },
        nextPlayer: (state, action) => {
            if(state.currentPlayer >= state.hits.length){
                state.hits.push(action.payload[0])
                state.bullseyes.push(action.payload[1])
            } else {
                state.hits[state.currentPlayer] = action.payload[0]
                state.bullseyes[state.currentPlayer] = action.payload[1]
            }
            state.currentPlayer++
        },
        previousPlayer: (state, action) => {
            state.currentPlayer--
        }
    }
})

export const selectCurrentPlayerIndex = (state) => state.shot.currentPlayer
export const selectCurrentHits = (state) => state.shot.hits
export const selectCurrentBullseyes = (state) => state.shot.bullseyes
export const selectShot = state => state.shot

export const {resetShot, nextPlayer, previousPlayer} = shotSlice.actions
export default shotSlice.reducer